/* eslint-disable import/no-extraneous-dependencies */
import * as React from 'react';
import {
  AppBarComponent,
  AppBarItem,
  AppBarLink,
  AppBarNavLink,
} from '@leagueplatform/web-common-components';
import { useIntl } from '@leagueplatform/locales';
import { Link } from '@leagueplatform/routing';
import { EVENT_NAME, trackAnalyticsEvent } from '@leagueplatform/analytics';
import { UserDropdown } from './user-dropdown/user-dropdown.component';
import { LinkType, NavLink, useNavLinksList } from './use-nav-links-list.hook';

const APP_NAV_DEFAULT_HEIGHT = 8 * 7; // 56px – 7 lines on the Genesis 8px grid

export const AppNavBarComponent = React.memo(() => {
  const { formatMessage } = useIntl();
  const { navLinks } = useNavLinksList();
  const sendNavBarAnalyticsEvent = (action: string) => () =>
    trackAnalyticsEvent(EVENT_NAME.NAVIGATION_TAB_CLICKED, {
      screen_name: 'Navigation Bar',
      detail: action,
    });

  const navLinkWrapper = (navLink: NavLink) => {
    switch (navLink.type) {
      case LinkType.LINK:
        return (
          <AppBarLink
            as={Link}
            to={navLink.to}
            key={navLink.message_id}
            onClick={sendNavBarAnalyticsEvent(navLink.message_id)}
            color="onSurface.text.primary"
            hoverStyle={{ color: 'interactive.action.primary' }}
          >
            {formatMessage({ id: navLink.message_id })}
          </AppBarLink>
        );
      case LinkType.NAV:
        return (
          <AppBarNavLink
            to={navLink.to}
            key={navLink.message_id}
            onClick={sendNavBarAnalyticsEvent(navLink.message_id)}
            color="onSurface.text.primary"
            hoverStyle={{ color: 'interactive.action.primary' }}
            decorativeBarColor="interactive.action.primary"
          >
            {formatMessage({ id: navLink.message_id })}
          </AppBarNavLink>
        );
      default:
        throw new Error('invalid link type');
    }
  };

  return (
    <AppBarComponent navHeight={APP_NAV_DEFAULT_HEIGHT}>
      {/* Spacer */}
      <AppBarItem flexGrow={{ _: 0, laptop: 2 }} aria-hidden="true" />
      {/* App Nav Home & Capability Areas */}
      {navLinks.map((navLink) => navLinkWrapper(navLink))}

      {/* Spacer */}
      <AppBarItem flexGrow={{ _: 1, laptop: 30 }} />

      {/* App Nav User Dropdown */}
      <AppBarItem>
        <UserDropdown />
      </AppBarItem>
      {/* Spacer */}
      <AppBarItem flexGrow={{ _: 0, laptop: 2 }} aria-hidden="true" />
    </AppBarComponent>
  );
});
