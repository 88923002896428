import type { DropdownSection } from '@leagueplatform/web-common-components';
import { LocalesKey } from 'types/types';

export type AppDropdownSection = (Omit<DropdownSection[number], 'text'> & {
  text: LocalesKey;
})[];

const userDropdownConfig: AppDropdownSection[] = [
  [
    {
      to: '/sign-out',
      text: 'STR_LOG_OUT',
      linkProps: {
        color: 'onSurface.text.critical',
      },
    },
  ],
];

export const useUserDropdownComponent = () => {
  const dropdownMenuItemProps = {
    preferredFirstName: 'John Doe',
    menuItemsConfig: userDropdownConfig,
  };

  return { data: dropdownMenuItemProps };
};
